import React, { useCallback, useState, useEffect } from "react";

import debounce from "../hoc/debounce";
import AuthContext from "../core/AuthContext";

import {
  ClientOnly,
  Container,
  ErrorMessage,
  ERROR_CODES,
  LoadingMessage,
  OnlineStatus,
  Checkbox,
  Text,
} from "../components";

import { logger } from "../core";
import {
  PROMO_SELECT_PLACEHOLDER,
  TRANSACTION_STATUS,
} from "../core/constants";
import {
  Cart,
  Footer,
  GatewayFrame,
  Header,
  Promotions,
  Scene,
  useOrder,
  utils,
} from "../layout/checkout";
import { isPurchaseDisabled } from "../core/config";
import { retry } from "../utils/retry";
import { Stack } from "basis";
import { getManualPayment } from "../layout/checkout/manualPayments";

const { updateOrder, addStatusChange, getStatusChanges } = utils;

const Checkout = () => {
  const [loading, setLoading] = useState(true);
  const [showCheckoutContent, setShowCheckoutContent] = useState(true);
  const [isAutoCaptureSelected, setAutoCaptureSelected] = useState(false);
  const [isManual, isManualPayment] = useState();
  const [currentState, setCurrentState] = useState(PROMO_SELECT_PLACEHOLDER)
  const {
    loading: isFetchingOrder,
    disablePayment,
    error,
    order,
    promotions,
    selectedPromotion,
    changeSelectedPromotion,
    setErrorMessage,
    setDisablePayment,
    params,
  } = useOrder();
  let showIdCheckbox = isManual === true && order?.idCheckEnabled === true 
  let showIdCheckboxCapture = isManual === true && isAutoCaptureSelected === false && order?.idCheckEnabled === true

  useEffect(() => {
    isManualPayment(getManualPayment());
  }, [isManual]);
  const handleCustomerClick = async (payload) => {
    // TODO: Notify API for customer click
    if(showIdCheckbox&&isAutoCaptureSelected === true){
      logger.info(`customer clicked, ID checkbox for transaction id: ${params.transactionId}`, {
        payload,
        statusChanges: getStatusChanges(),
      });
    }
    logger.info(`customer clicked, transaction id: ${params.transactionId}`, {
      payload,
      statusChanges: getStatusChanges(),
    });

    setShowCheckoutContent(false);
  };
  const handleCurrentState = (value) => {
    setCurrentState(value)
  }
  const handleAutoCaptureChange = (value) => {
    setAutoCaptureSelected(value)
    setDisablePayment(false);
    if (value === false || currentState === PROMO_SELECT_PLACEHOLDER) {
      setDisablePayment(true);
    }
    if (value === true && promotions.length === 1) {
      setDisablePayment(false);
    }
  };
  const handleCompletePayment = useCallback(
    debounce(async (paymentResponse) => {
      if (loading) {
        return;
      }

      logger.info(
        `payment response from gateway, transaction id: ${params.transactionId}`,
        {
          paymentResponse,
          statusChanges: getStatusChanges(),
        }
      );

      setLoading(true);

      try {
        const { url, status } = await retry({
          fn: () =>
            updateOrder(
              params.transactionToken,
              params.transactionId,
              paymentResponse,
              params.isManual
            ),
          onRetryBegin: (numAttempt) =>
            numAttempt > 0 &&
            logger.error(`handleCompletePayment(): retry #${numAttempt}`, {
              params,
              paymentResponse,
              statusChanges: getStatusChanges(),
            }),
          attempts: 10,
          sleepMs: 200,
          sleepMsDelay: [
            1000,
            2000,
            3000,
            4000,
            5000,
            6000,
            7000,
            8000,
            9000,
            10000,
          ],
        });

        if (
          status.toUpperCase() === TRANSACTION_STATUS.CANCELLED.toUpperCase()
        ) {
          logger.error(`handleCompletePayment(): ${status}`, {
            params,
            paymentResponse,
            statusChanges: getStatusChanges(),
          });

          setErrorMessage(ERROR_CODES.PAYMENT_CANCELLED_AFTER_INITIATE);
          return;
        }

        window.location.replace(url);
      } catch (err) {
        logger.error(`handleCompletePayment(): failed ${err}`, {
          params,
          paymentResponse,
          statusChanges: getStatusChanges(),
        });

        setErrorMessage(ERROR_CODES.PAYMENT_FAILED);
      }
    }),
    [selectedPromotion, params, loading]
  );

  const handlePromotionChange = (value) => {
    setLoading(true);

    if (value === PROMO_SELECT_PLACEHOLDER) {
      setDisablePayment(true);
      return;
    }

    // set loading to false if payment was previously disable.
    // since iframe was already loaded, we just enable the payment
    if (disablePayment) {
      setLoading(false);
    }

    setDisablePayment(false);
    changeSelectedPromotion(value);
    if (showIdCheckboxCapture) {
      setDisablePayment(true);
    }
  };

  if (isFetchingOrder) {
    return (
      <LoadingMessage message="Getting transaction details while you wait .." />
    );
  }

  if (isPurchaseDisabled) {
    return (
      <ClientOnly>
        <Scene title="Easy checkout" currency={order?.currency}>
          <Scene.FullWidth>
            <ErrorMessage type={ERROR_CODES.PURCHASE_DISABLED} />
          </Scene.FullWidth>
        </Scene>
      </ClientOnly>
    );
  }

  if (error?.message) {
    return (
      <ClientOnly>
        <Scene title="Easy checkout" currency={order?.currency}>
          <Scene.FullWidth>
            <ErrorMessage
              type={error?.message}
              additionalParams={{
                minimumAmount: order?.minimumAmount,
                currency: order?.currency,
                urlCancel: order?.urlCancel,
                transactionId: order?.transactionId,
              }}
            />
          </Scene.FullWidth>
        </Scene>
      </ClientOnly>
    );
  }

  return (
    <ClientOnly>
      <AuthContext.Provider value={params.transactionToken}>
        <Scene currency={order?.currency} loading={!showCheckoutContent}>
          <Scene.Summary>
            <Container gutters>
              <Cart order={order} />
              <Footer order={order} />
            </Container>
          </Scene.Summary>

          <Scene.PaymentDetails>
            <Container gutters>
              <Promotions
                title="Step 1"
                loading={loading}
                promotions={promotions}
                currentState={handleCurrentState}
                selectedPromotion={selectedPromotion}
                onChange={handlePromotionChange}
                order={order}
              />
              {showIdCheckbox && (<Container padding="10 0 4">
                <Stack gap="1">
                  <Text textStyle="heading5" margin="0 0 2">
                    Step 2
                  </Text>
                  <Text color="grey.t75" margin="0 0 2">
                    Before you complete the payment on behalf of customer, you need to verify their identity
                  </Text>
                  <Checkbox
                    name="isAutoCaptureSelected"
                    label="ID Check Completed?"
                    selected={isAutoCaptureSelected}
                    onChange={handleAutoCaptureChange}
                  ></Checkbox>
                </Stack>
              </Container>)}
              {showIdCheckbox ?
                <Header step='Step 3' currency={order?.currency} /> :
                <Header currency={order?.currency} />
              }

              <Container>
                <OnlineStatus onChange={addStatusChange} />
              </Container>

              <GatewayFrame
                hideIframe={disablePayment}
                transactionId={params.transactionId}
                amount={order?.amount}
                promotion={selectedPromotion}
                loading={loading}
                onCustomerClick={handleCustomerClick}
                onResponse={handleCompletePayment}
                onLoaded={() => setLoading(false)}
                onError={() => setErrorMessage(ERROR_CODES.UNEXPECTED_ACTIVITY)}
              />
            </Container>
          </Scene.PaymentDetails>
        </Scene>
      </AuthContext.Provider>
    </ClientOnly>
  );
};

export default Checkout;
